import * as React from "react"

import * as styles from "../components/index.module.css"
import Header from "../components/header"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => (
  <>
  <Header />
  <div className={styles.index_kv}>
  <StaticImage src="../images/index_bg.jpg" alt="格安ランディングページ制作" placeholder="blurred" />
  </div>
  <div className={styles.index_link}>
  <a href="lp" className={styles.index_link_block}>Bgreen LPはこちら</a>
  <a href="https://bgreen.co.jp/" className={styles.index_link_block}>Bgreen 公式サイト</a>

  </div>
  
  <Footer />
  </>
)

export default IndexPage
